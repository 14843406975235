import React from 'react'
import Seo from "../../components/seo/index";
import Layout from "./layout/index";
import AdminDataTable from './components/adminDataTable'

const BibDetails = () => {
  return (
    <div>
    <Seo title="Green Heartfulness Run 2024" />
    <Layout isSticky>
      <AdminDataTable />
    </Layout>
  </div>
  )
}

export default BibDetails